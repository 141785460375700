.footer-wrapper{
    background-color:whitesmoke;
    margin-top: -0.4rem;
    
}
.footerLogo{
    -webkit-filter: grayscale(100%);
    filter: grayscale(120%);
    width: 16rem;
}
.menu-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-link{
    color: rgb(130, 130, 130);
    font-weight: 700;
    text-decoration: none;
    
}
.footer-link:hover {
    color: rgb(104, 103, 103);
    text-decoration: underline;
}

.footer-link:active {
    color: grey;
    text-decoration: none;
}
.text-style{
    color: rgb(83, 83, 83);
    font-weight: 700;
    text-transform: uppercase;
}
.address{
    color: rgb(130, 130, 130);
    font-weight: 500;
    text-decoration: none;
    font-size: 0.95rem;
}
@media screen and (max-width:768px) {
  .mobileMargin{
    padding-top: 2rem;
  }
}