.customBtn{
    position:relative;
    z-Index:1;
    color:#0E6129;
    font-weight:500;
    border-width:2;
    border-color:#0E6129;
}
.customBtn:hover{
    background-color: #0E6129;
    color: white;
    border-width:2;
    border-color:#0E6129;
}
.companyName{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    color: #0E6129;
}