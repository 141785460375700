.counter-wrapper{
    background-color: aliceblue;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.number{
    color: #0D6129;
    font-size: x-large;
    font-weight: 800;
}
.numberTitle{
    color: #94ab9b;
    font-size: large;
    font-weight: 800;
    margin-top: -1.2rem;
}