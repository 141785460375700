.logo {
    width: 13rem;
}

.navlinks {
    gap: 2rem
}

.link {
    color: #0E6129;
    font-weight: 600;
    text-decoration: none;
}

.link:hover {
    color: #F22B0D;
    text-decoration: underline;
}

.link:active {
    color: #0E6129;
    text-decoration: none;
}
.dropItemsStyle{
    color: #0E6129;
    font-weight: 600;
}
.dropItemsStyle:hover{
    color: #F22B0D;
    text-decoration: underline;
    background-color: transparent;
}
