.accordion-button {
    background-color:  #F5F5F5 !important;
    
  }
  
  .accordion-button:focus {
    box-shadow: none;
  }
  
  .accordion-button:not(.collapsed) {
    color: #212529;
  }