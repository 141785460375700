.Heading{
    color:#F22B0D;
    font-weight: bold;
    text-transform: uppercase;
}
.companyLogo{
    height: 15rem;
    width: 15rem;
}
.companyMarginContent{
    display:flex;
    justify-content: center;
    margin-top: -2rem;
    margin-bottom: -2rem;      
}
.centerContainer{
    display:flex;
    justify-content: center;
 
}
.horLine{
    border-color: #a6a6a6;
    border-width: 1px;
}
html,body{
    overflow-x: hidden;
}